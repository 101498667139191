import type { AboutUs } from '$lib/domain/about-us.interface';
import type { Article } from '$lib/domain/article.interface';
import type { BasicContent } from '$lib/domain/basic-content.interface';
import type { ClientReference } from '../domain/client-reference.interface';
import type { Industry } from "$lib/domain/industry.interface";
import type { Service } from '../domain/service.interface';
import type { Review } from '$lib/domain/review.interface';
import type { Vacancy } from '$lib/domain/vacancy';
import type { Blog } from '$lib/domain/blog.interface';
import type { Feedback } from "$lib/domain/feedback.interface";
import { ArticleCategoryEnum } from '$lib/domain/article-category.enum';
import { BlogCategoryEnum } from '$lib/domain/blog-category.enum';
import { openGraphImageUrl } from "$lib/config/system";

export const aboutUs: AboutUs[] = [
  {
    icon: 'aboutus1.webp',
    title: 'Top service providers by Yahoo!',
    date: "November 28, 2019",
    url: 'https://www.yahoo.com/entertainment/designrush-reveals-best-technology-experts-103500705.html'
  },
  {
    icon: 'aboutus2.webp',
    title: 'Top 100 web development companies in Sweden',
    date: 'November 28, 2019',
    url: 'https://justcreateapp.com/top-healthcare-software-development-companies'
  },
  {
    icon: 'aboutus3.webp',
    title: 'Best web development companies for October 2022',
    date: 'November 28, 2019',
    url: 'https://thewebmind.org/top-10-best-web-development-companies-in-ukraine?fbclid=IwAR1zBHuy-OyxI0mnxL0XgfYvRKU59gyMIQvl4m80k0KYtA7iqD695be3cWA'
  },
];

export const blogs: Blog[] = [
  {
    title: 'Detailed guide on building enterprise app',
    url: '/detailed-guide-on-building-enterprise-app/',
    tags: [
        BlogCategoryEnum['Business App']
    ],
    date: new Date(2023, 11, 29),
    author: 'ivan'
  },
  {
    title: 'Technology for Elderly Care Services: a Purpose-Driven Approach Demystified',
    url: '/technology-for-elderly-care-services/',
    tags: [
        BlogCategoryEnum['Healthcare'],
        BlogCategoryEnum['Product']
    ],
    date: new Date(2023, 8, 28),
    author: 'oleg'
  },
  {
    title: 'Basics of Developing On-Demand Service App',
    url: '/basics-of-developing-on-demand-service-app/',
    tags: [
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2024, 2, 29),
    author: 'ivan'
  },
  {
    title: 'How to build a product from scratch',
    url: '/how-to-build-a-product-from-scratch/',
    tags: [
        BlogCategoryEnum['Product']
    ],
    date: new Date(2024, 3, 13),
    author: 'oleg'
  },
  {
    title: 'How to Implement GPT into Your App',
    url: '/how-to-implement-gpt-into-your-app/',
    tags: [
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2023, 4, 12),
    author: 'ivan'
  },
  {
    title: 'Medtech Trends to Watch in 2024',
    url: '/medtech-trends/',
    tags: [
        BlogCategoryEnum['Healthcare']]
    ,
    date: new Date(2023, 3, 1),
    author: 'oleg'
  },
  {
    title: 'What is SaMD? All About Software as a Medical Device',
    url: '/what-is-samd-all-about-software-as-a-medical-device/',
    tags: [
        BlogCategoryEnum['Healthcare'],
        BlogCategoryEnum['SaMD'],
    ],
    date: new Date(2023, 2, 6),
    author: 'oleg'
  },
  {
    title: '7 Advantages of Developing User Personas Prior to App Development',
    url: '/7-advantages-of-developing-user-personas-prior-to-app-development/',
    tags: [
        BlogCategoryEnum['UI/UX Design']
    ],
    date: new Date(2022, 10, 17),
    author: 'ivan'
  },
  {
    title: 'Flutter Pros and Cons for App Development',
    url: '/flutter-pros-and-cons-for-app-development/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2022, 9, 22),
    author: 'ivan'
  },
  {
    title: 'ERP for Construction Industry and Infrastructure',
    url: '/erp-for-construction-industry-and-infrastructure/',
    tags: [
        BlogCategoryEnum['Construction'],
        BlogCategoryEnum['ERP'],
        BlogCategoryEnum['Business App'],
    ],
    date: new Date(2024, 3, 19),
    author: 'oleg'
  },
  {
    title: 'How to Build a Mobile Banking App',
    url: '/how-to-build-a-mobile-banking-app/',
    tags: [
        BlogCategoryEnum['Finance'],
        BlogCategoryEnum['Digital payments'],
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2024, 3, 7),
    author: 'ivan'
  },
  {
    title: 'Software Outsourcing to Ukraine: Reasons to Hire Ukrainian Developers',
    url: '/software-outsourcing-ukraine-hire-software-developers/',
    tags: [
        BlogCategoryEnum['Outsourcing'],
        BlogCategoryEnum['Team Extension']
    ],
    date: new Date(2022, 6, 24),
    author: 'ivan'
  },
  {
    title: 'What Can You Do with Java and What is it Used for?',
    url: '/what-can-you-do-with-java/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2022, 6, 16),
    author: 'ivan'
  },
  {
    title: 'How To Manage a Software Development Team',
    url: '/how-to-manage-software-development-team/',
    tags: [
        BlogCategoryEnum['Outsourcing'],
        BlogCategoryEnum['Team Extension']
    ],
    date: new Date(2022, 4, 19),
    author: 'ivan'
  },
  {
    title: '.NET vs Node.js: What to Choose',
    url: '/net-vs-node-js/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2021, 11, 17),
    author: 'ivan'
  },
  {
    title: 'Native vs Cross Platform Development',
    url: '/native-vs-cross-platform-development/',
    tags: [
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2021, 10, 25),
    author: 'ivan'
  },
  {
    title: 'The Difference between UI and UX Design',
    url: '/ui-vs-ux/',
    tags: [
        BlogCategoryEnum['UI/UX Design']
    ],
    date: new Date(2021, 10, 14),
    author: 'ivan'
  },
  {
    title: 'Healthcare Website Design: 15 Examples & Best Practices',
    url: '/healthcare-website-design/',
    tags: [
        BlogCategoryEnum['Healthcare'],
        BlogCategoryEnum['Web']
    ],
    date: new Date(2024, 2, 20),
    author: 'oleg'
  },
  {
    title: 'What is a LIMS? What does LIMS stand for?',
    url: '/what-is-a-lims/',
    tags: [
        BlogCategoryEnum['Healthcare'],
        BlogCategoryEnum['LIMS'],
        BlogCategoryEnum['Business App']
    ],
    date: new Date(2024, 4, 5),
    author: 'ivan'
  },
  {
    title: 'Warehouse Management: Definition, Process, Practices',
    url: '/what-is-warehouse-management-system/',
    tags: [
        BlogCategoryEnum['Logistics'],
        BlogCategoryEnum['Warehouse management']
    ],
    date: new Date(2024, 4, 4),
    author: 'ivan'
  },
  {
    title: 'Logistics Management Software',
    url: '/best-logistics-software/',
    tags: [
        BlogCategoryEnum['Logistics']
    ],
    date: new Date(2024, 3, 28),
    author: 'oleg'
  },
  {
    title: 'What is no code? A Guide to No-Code Development',
    url: '/what-is-no-code-development/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2021, 7, 6),
    author: 'ivan'
  },
  {
    title: 'Software Development Methodologies with Pros and Cons',
    url: '/software-development-methodologies-pros-cons/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2021, 4, 28),
    author: 'ivan'
  },
  {
    title: 'Pros and Cons of .NET Development',
    url: '/pros-cons-net-development/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2021, 4, 23),
    author: 'ivan'
  },
  {
    title: 'Android vs iOS Development: Pros and Cons for Your App',
    url: '/android-vs-ios-development-pros-cons/',
    tags: [
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2021, 4, 10),
    author: 'ivan'
  },
  {
    title: 'Enterprise Digital Transformation: Best Practices',
    url: '/enterprise-digital-transformation-best-practices/',
    tags: [
        BlogCategoryEnum['Product'],
        BlogCategoryEnum['Digital Transformation']
    ],
    date: new Date(2021, 4, 7),
    author: 'oleg'
  },
  {
    title: 'How to Make Your Telemedicine Platform HIPAA-Compliant',
    url: '/hipaa-compliant-telehealth-platform/',
    tags: [
        BlogCategoryEnum['Healthcare'],
        BlogCategoryEnum['Telemedicine']
    ],
    date: new Date(2024, 4, 10),
    author: 'oleg'
  },
  {
    title: 'How Much Does Telemedicine Software Development Cost?',
    url: '/cost-of-telemedicine-software/',
    tags: [
        BlogCategoryEnum['Healthcare'],
        BlogCategoryEnum['Telemedicine'],
        BlogCategoryEnum['Product'],
    ],
    date: new Date(2024, 1, 11),
    author: 'oleg'
  },
  {
    title: 'Pros and Cons of Xamarin App Development',
    url: '/pros-and-cons-of-xamarin-app-development/',
    tags: [
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2021, 2, 21),
    author: 'ivan'
  },
  {
    title: 'Functional and Non-functional Requirements: Key Differences',
    url: '/functional-non-functional-requirements/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2021, 2, 11),
    author: 'ivan'
  },
  {
    title: 'Mobile Application Architecture: Best Practices',
    url: '/mobile-app-architecture/',
    tags: [
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2021, 2, 9),
    author: 'ivan'
  },
  {
    title: 'What is Human-Centered Design and Why It Matters',
    url: '/what-is-human-centered-design/',
    tags: [
        BlogCategoryEnum['UI/UX Design']
    ],
    date: new Date(2021, 2, 4),
    author: 'ivan'
  },
  {
    title: 'System Development Life Cycle (SDLC): Phases, Models, and Benefits',
    url: '/system-development-life-cycle/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2024, 2, 2),
    author: 'ivan'
  },
  {
    title: 'Web Application Architecture: Types, Components, and Trends',
    url: '/web-app-architecture-types-components-trends/',
    tags: [
        BlogCategoryEnum['Web']
    ],
    date: new Date(2021, 1, 27),
    author: 'ivan'
  },
  {
    title: 'Healthcare App Trends You Need to Follow',
    url: '/healthcare-mobile-app-trends/',
    tags: [
        BlogCategoryEnum['Healthcare'],
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2021, 1, 25),
    author: 'oleg'
  },
  {
    title: 'How to Create a Messaging App Like WhatsApp',
    url: '/how-to-create-a-messaging-app-like-whatsapp/',
    tags: [
        BlogCategoryEnum['Media & Entertainment'],
        BlogCategoryEnum['Mobile'],
    ],
    date: new Date(2021, 1, 22),
    author: 'ivan'
  },
  {
    title: 'Top JavaScript Frameworks for Front-End Development',
    url: '/top-javascript-frameworks-for-front-end-development/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2021, 1, 20),
    author: 'ivan'
  },
  {
    title: 'Benefits of Telehealth for Patients and Doctors',
    url: '/benefits-of-telehealth-for-patients-doctors/',
    tags: [
        BlogCategoryEnum['Healthcare'],
        BlogCategoryEnum['Telemedicine']
    ],
    date: new Date(2024, 5, 27),
    author: 'oleg'
  },
  {
    title: 'How to Build a Startup from Scratch',
    url: '/how-to-build-startup-from-scratch/',
    tags: [
        BlogCategoryEnum['Startup Services']
    ],
    date: new Date(2021, 1, 16),
    author: 'ivan'
  },
  {
    title: 'How to Create a GPS App',
    url: '/make-gps-app-android-ios/',
    tags: [
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2021, 1, 14),
    author: 'ivan'
  },
  {
    title: 'Angular Development: Pros and Cons',
    url: '/angular-pros-and-cons/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2021, 1, 13),
    author: 'ivan'
  },
  {
    title: 'Choosing a Technology Stack for Web Application Development',
    url: '/choosing-a-technology-stack-for-web-application-development/',
    tags: [
        BlogCategoryEnum['Web'],
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2019, 10, 14),
    author: 'ivan'
  },
  {
    title: 'The Role of a CTO in a Startup. Mission, Responsibilities, and Skills',
    url: '/the-role-of-a-cto-in-a-startup-mission-responsibilities-and-skills/',
    tags: [
        BlogCategoryEnum['Startup Services']
    ],
    date: new Date(2019, 9, 30),
    author: 'ivan'
  },
  {
    title: 'How Uber Freight Is Changing the Freight Forwarding Market',
    url: '/how-uber-freight-is-changing-the-freight-forwarding-market/',
    tags: [
        BlogCategoryEnum['Logistics'],
        BlogCategoryEnum['Freight Forwarding']
    ],
    date: new Date(2019, 9, 16),
    author: 'ivan'
  },
  {
    title: 'MVP vs MCP: What to Choose for Your Project?',
    url: '/mvp-vs-mcp-what-to-choose-for-your-project/',
    tags: [
        BlogCategoryEnum['Product'],
        BlogCategoryEnum['MVP']
    ],
    date: new Date(2024, 1, 8),
    author: 'ivan'
  },
  {
    title: 'Why a Fixed-Price Contract Is a Bad Idea for Your Project?',
    url: '/why-a-fixed-price-contract-is-a-bad-idea-for-your-project/',
    tags: [
        BlogCategoryEnum['Outsourcing'],
        BlogCategoryEnum['Team Extension']
    ],
    date: new Date(2019, 8, 8),
    author: 'ivan'
  },
  {
    title: 'Time and Materials vs Fixed Price vs Scope Based Model: Choosing the Right Pricing Model for Your Project',
    url: '/time-and-materials-vs-fixed-price-vs-scope-based-model-choosing-the-right-pricing-model-for-your-project/',
    tags: [
        BlogCategoryEnum['Outsourcing'],
        BlogCategoryEnum['Team Extension']
    ],
    date: new Date(2019, 7, 29),
    author: 'ivan'
  },
  {
    title: 'Top 6 Logistics Startups to Watch Out',
    url: '/top-6-logistics-startups-to-watch-out/',
    tags: [
        BlogCategoryEnum['Logistics']
    ],
    date: new Date(2019, 7, 22),
    author: 'oleg'
  },
  {
    title: 'Why Every Logistics Business Must Go Mobile',
    url: '/why-every-logistics-business-must-go-mobile/',
    tags: [
        BlogCategoryEnum['Logistics'],
        BlogCategoryEnum['Digital Transformation'],
        BlogCategoryEnum['Mobile'],
    ],
    date: new Date(2024, 4, 23),
    author: 'oleg'
  },
  {
    title: 'How to Start a Mobile Dating App',
    url: '/how-to-start-a-mobile-dating-app/',
    tags: [
        BlogCategoryEnum['Media & Entertainment'],
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2017, 11, 8),
    author: 'ivan'
  },
  {
    title: '7 Things To Take Into Account Before Launching A Startup',
    url: '/7-things-to-take-into-account-before-launching-a-startup/',
    tags: [
        BlogCategoryEnum['Startup Services']
    ],
    date: new Date(2017, 10, 26),
    author: 'ivan'
  },
  {
    title: '12 Startup Tools You Should Try Out',
    url: '/12-startup-tools-you-should-know-about/',
    tags: [
        BlogCategoryEnum['Startup Services']
    ],
    date: new Date(2017, 10, 5),
    author: 'ivan'
  },
  {
    title: 'How to Make Agile and Outsourcing Work?',
    url: '/how-to-make-agile-and-outsourcing-work/',
    tags: [
        BlogCategoryEnum['Outsourcing'],
        BlogCategoryEnum['Team Extension']
    ],
    date: new Date(2024, 4, 5),
    author: 'ivan'
  },
  {
    title: '5 Things You Need to Automate in Your Business',
    url: '/5-things-you-need-to-automate-in-your-business/',
    tags: [
        BlogCategoryEnum['Product'],
        BlogCategoryEnum['Digital Transformation']
    ],
    date: new Date(2017, 8, 30),
    author: 'ivan'
  },
  {
    title: 'Event Apps as a Growing Trend in the App Development',
    url: '/event-apps-as-a-growing-trend-in-the-app-development/',
    tags: [
        BlogCategoryEnum['Media & Entertainment'],
        BlogCategoryEnum['Mobile'],
    ],
    date: new Date(2017, 8, 17),
    author: 'ivan'
  },
  {
    title: '6 Tips for Managing an Outsourced Team',
    url: '/6-tips-for-managing-an-outsourced-team/',
    tags: [
        BlogCategoryEnum['Outsourcing'],
        BlogCategoryEnum['Team Extension']
    ],
    date: new Date(2017, 7, 19),
    author: 'ivan'
  },
  {
    title: '10 Reasons to Outsource the Software Development',
    url: '/10-reasons-to-outsource-the-software-development/',
    tags: [
        BlogCategoryEnum['Outsourcing'],
        BlogCategoryEnum['Team Extension']
    ],
    date: new Date(2017, 7, 6),
    author: 'ivan'
  },
  {
    title: '10 Common Mistakes When Outsourcing the Software Development',
    url: '/10-common-mistakes-when-outsourcing-the-software-development/',
    tags: [
        BlogCategoryEnum['Outsourcing'],
        BlogCategoryEnum['Team Extension']
    ],
    date: new Date(2017, 6, 15),
    author: 'ivan'
  },
  {
    title: 'How Much It Costs to Develop an App like Uber',
    url: '/how-much-it-costs-to-develop-an-app-like-uber/',
    tags: [
        BlogCategoryEnum['Logistics'],
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2017, 6, 1),
    author: 'ivan'
  },
  {
    title: 'Android vs iOS: Where to Start Building Your App',
    url: '/android-vs-ios-where-to-start-building-your-app/',
    tags: [
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2017, 5, 11),
    author: 'ivan'
  },
  {
    title: '11 Outstanding Free Travel Apps',
    url: '/11-outstanding-free-travel-apps/',
    tags: [
        BlogCategoryEnum['Travel & Hospitality'],
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2017, 4, 26),
    author: 'ivan'
  },
  {
    title: '7 amazing project management tools',
    url: '/7-amazing-project-management-tools/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2017, 4, 12),
    author: 'ivan'
  },
  {
    title: '5 Tips for Outsourcing Product Development',
    url: '/5-tips-for-outsourcing-product-development/',
    tags: [
        BlogCategoryEnum['Product'],
        BlogCategoryEnum['Outsourcing']
    ],
    date: new Date(2017, 3, 15),
    author: 'ivan'
  },
  {
    title: 'Make Entity Framework 10x faster in 20 minutes',
    url: '/make-entity-framework-10x-faster-in-20-minutes/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2017, 3, 9),
    author: 'ivan'
  },
  {
    title: 'Healthcare mobile apps which make a change',
    url: '/healthcare-mobile-apps-which-make-a-change/',
    tags: [
        BlogCategoryEnum['Healthcare'],
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2024, 2, 14),
    author: 'oleg'
  },
  {
    title: 'Top 9 wireframing and prototyping tools',
    url: '/top-9-wireframing-and-prototyping-tools/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2017, 2, 15),
    author: 'ivan'
  },
  {
    title: 'Creating an online store: hosted vs self-hosted e-commerce platform',
    url: '/creating-an-online-store-hosted-vs-self-hosted-ecommerce-platform/',
    tags: [
        BlogCategoryEnum['Web']
    ],
    date: new Date(2017, 2, 8),
    author: 'ivan'
  },
  {
    title: 'What are the characteristics of successful startups?',
    url: '/what-are-the-characteristics-of-successful-startups/',
    tags: [
        BlogCategoryEnum['Startup Services']
    ],
    date: new Date(2017, 2, 1),
    author: 'ivan'
  },
  {
    title: '11 most popular alternatives to WordPress',
    url: '/11-most-popular-alternatives-to-wordpress/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2017, 1, 25),
    author: 'ivan'
  },
  {
    title: 'What you need to know about building MVP',
    url: '/what-you-need-to-know-about-building-mvp/',
    tags: [
        BlogCategoryEnum['Product'],
        BlogCategoryEnum['MVP']
    ],
    date: new Date(2024, 1, 30),
    author: 'ivan'
  },
  {
    title: 'Desktop or Web Application: What to Develop',
    url: '/desktop-or-web-application-what-to-develop/',
    tags: [
        BlogCategoryEnum['Web']
    ],
    date: new Date(2017, 1, 11),
    author: 'ivan'
  },
  {
    title: '7 Mobile App Development Trends',
    url: '/7-mobile-app-development-trends/',
    tags: [
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2016, 12, 20),
    author: 'ivan'
  },
  {
    title: 'Design thinking approach in software development',
    url: '/design-thinking-approach-in-software-development/',
    tags: [
        BlogCategoryEnum['UI/UX Design']
    ],
    date: new Date(2016, 12, 13),
    author: 'ivan'
  },
  {
    title: '10 Lightweight Bootstrap Alternatives',
    url: '/10-lightweight-bootstrap-alternatives/',
    tags: [
        BlogCategoryEnum['Technology']
    ],
    date: new Date(2016, 12, 6),
    author: 'ivan'
  },
  {
    title: 'Online payment systems: which way to go',
    url: '/online-payment-systems-which-way-to-go/',
    tags: [
        BlogCategoryEnum['Finance'],
        BlogCategoryEnum['Digital payments']
    ],
    date: new Date(2016, 11, 18),
    author: 'ivan'
  },
  {
    title: 'Top web development trends you must know about',
    url: '/top-web-development-trends-you-must-know-about/',
    tags: [
        BlogCategoryEnum['Web']
    ],
    date: new Date(2016, 11, 8),
    author: 'ivan'
  },
  {
    title: 'Choosing a Development Team for a Startup: In-house or Outsourced?',
    url: '/development-team-for-startup/',
    tags: [
        BlogCategoryEnum['Startup Services'],
        BlogCategoryEnum['Outsourcing'],
        BlogCategoryEnum['Team Extension'],
    ],
    date: new Date(2016, 10, 27),
    author: 'ivan'
  },
  {
    title: 'How to Build a Healthcare App: A Complete Guide',
    url: '/healthcare-app-development-process/',
    tags: [
        BlogCategoryEnum['Healthcare'],
        BlogCategoryEnum['Mobile']
    ],
    date: new Date(2024, 1, 4),
    author: 'oleg'
  },
  {
    title: 'How to Build an EHR System: A Complete Guide',
    url: '/ehr-system-development-process/',
    tags: [
        BlogCategoryEnum['Mobile'],
        BlogCategoryEnum['Web']
    ],
    date: new Date(2023, 12, 15),
    author: 'ivan'
  },
  {
    title: 'Healthcare of the Future: Connecting Patients with Doctors',
    url: '/healthcare-of-the-future/',
    tags: [
        BlogCategoryEnum['Healthcare']
    ],
    date: new Date(2023, 8, 1),
    author: 'oleg'
  },
  {
    title: 'An In-Depth Guide to Trucking App Development',
    url: '/trucking-app-development-guide/',
    tags: [
        BlogCategoryEnum['Mobile'],
        BlogCategoryEnum['Web']
    ],
    date: new Date(2023, 12, 20),
    author: 'ivan'
  },
  {
    title: 'Dynamic Route Planning & Optimization in Logistics',
    url: '/dynamic-route-planning-and-optimization-explained/',
    tags: [
        BlogCategoryEnum['Business App'],
    ],
    date: new Date(2024, 1, 24),
    author: 'ivan'
  },
  {
    title: 'Everything You Need to Know Before You Start Developing a Pharmacy App',
    url: '/pharmacy-app-development-process/',
    tags: [
				BlogCategoryEnum['Healthcare']
    ],
    date: new Date(2024, 2, 6),
    author: 'oleg'
  },
  {
    title: 'Building a Fleet Management App: Essential Features and Development Process',
    url: '/fleet-management-app-development/',
    tags: [
        BlogCategoryEnum['Business App'],
    ],
    date: new Date(2024, 2, 15),
    author: 'ivan'
  },
  {
    title: 'Last-Mile Delivery Technology: Benefits, Application, Best Practices',
    url: '/what-is-last-mile-delivery-technology/',
    tags: [
        BlogCategoryEnum['Business App'],
    ],
    date: new Date(2024, 2, 19),
    author: 'ivan'
  },
  {
    title: 'How to Develop a Construction App: A Deep Dive',
    url: '/construction-app-development-guide/',
    tags: [
        BlogCategoryEnum['Construction'],
    ],
    date: new Date(2024, 3, 12),
    author: 'ivan'
  },
	{
		title: 'Top 5 Supply Chain Management Software Requirements',
		url: '/supply-chain-management-software-features-requirements/',
		tags: [
				BlogCategoryEnum['Logistics']
		],
		date: new Date(2024, 3, 21),
		author: 'ivan'
	},
	{
		title: '10 New Technologies in the Construction Industry to Watch in 2024 and Beyond',
		url: '/construction-industry-technology-trends/',
		tags: [
				BlogCategoryEnum['Construction']
		],
		date: new Date(2024, 4, 24),
    author: 'oleg'
  },
  {   
		title: 'What is EHR in Healthcare and How it Improves Patient Care',
		url: '/ehr-for-patient-care/',
		tags: [
				BlogCategoryEnum['Healthcare']
		],
		date: new Date(2024, 5, 1),
		author: 'oleg'
	},
  {
    title: 'How to Create a LIMS System: 5 Steps Guide',
		url: '/how-to-create-laboratory-information-management-system/',
		tags: [
				BlogCategoryEnum['Healthcare'],
				BlogCategoryEnum['LIMS']
		],
		date: new Date(2024, 5, 3),
		author: 'ivan'
  },      
  {
    title: 'Top 10 Strategies for Supply Chain Process Improvements',
		url: '/supply-chain-improvement-guide/',
		tags: [
				BlogCategoryEnum['Logistics']
		],
		date: new Date(2024, 5, 31),
		author: 'ivan'
	},
  {
		title: '7 Warehouse Optimization Tips To Maximize Your Profit',
		url: '/warehouse-optimization-tips/',
		tags: [
				BlogCategoryEnum['Logistics'],
				BlogCategoryEnum['Warehouse management']
		],
		date: new Date(2024, 9, 11),
    author: 'ivan'
  },
	{
		title: '7 Pillars of a Winning Telehealth Growth Strategy',
		url: '/the-pillars-of-a-winning-telehealth-strategy/',
		tags: [
				BlogCategoryEnum['Healthcare'],
				BlogCategoryEnum['Telemedicine'],
		],
		date: new Date(2024, 9, 12),
		author: 'oleg'
	},
  {
    title: 'How to Create a Remote Patient Monitoring Platform',
		url: '/remote-patient-monitoring-development-guide/',
		tags: [
				BlogCategoryEnum['Healthcare'],
				BlogCategoryEnum['Telemedicine'],
				BlogCategoryEnum['SaMD']
		],
		date: new Date(2024, 9, 18),
		author: 'ivan'
  },
  {
    title: 'How to Build a Personal Finance App: Steps, Cost, and Key Features',
		url: '/personal-finance-app-development-guide/',
		tags: [
				BlogCategoryEnum['Finance'],
				BlogCategoryEnum['Business App']
		],
		date: new Date(2024, 7, 22),
		author: 'ivan'
  },
]

export const articles: Article[] = [
  {
    title: 'A SaaS-Based Resident Experience Platform for Senior Living',
    tags: [
        ArticleCategoryEnum['Platform'],
        ArticleCategoryEnum['SaaS Development'],
        ArticleCategoryEnum['Mobile'],
        ArticleCategoryEnum['Web'],
        ArticleCategoryEnum['UI/UX Design'],
        ArticleCategoryEnum['Healthcare'],
    ],
    url: '/our-works/saas-based-resident-experience-platform-for-senior-living/',
    backgroundImage: 'saas-based-resident-experience-platform-for-senior-living-cover.webp',
    thumbnailImage: 'saas-based-resident-experience-platform-for-senior-living-thumb.webp',
    caseStudyTitle: 'A SaaS-Based Resident Experience Platform for Senior Living',
    relatedArticleUrls: [
      '/technology-for-elderly-care-services/',
      '/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
      '/healthcare-of-the-future/'
    ],
    date: new Date(2023, 9, 6, 7, 3, 0, 0)
  },
  {
    title: 'Business Digitalization for a Chain of Canadian Medical Clinics',
    tags: [
        ArticleCategoryEnum['Healthcare'],
        ArticleCategoryEnum['Telemedicine'],
        ArticleCategoryEnum['Digital Transformation'],
        ArticleCategoryEnum['Platform'],
        ArticleCategoryEnum['Product'],
        ArticleCategoryEnum['Mobile'],
        ArticleCategoryEnum['Web'],
    ],
    url: '/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
    backgroundImage: 'business-digitalization-for-a-chain-of-canadian-medical-clinics.webp',
    thumbnailImage: 'business-digitalization-for-a-chain-of-canadian-medical-clinics-thumb.webp',
    caseStudyTitle: 'Business Digitalization for a Chain of Canadian Medical Clinics',
    relatedArticleUrls: [
      '/healthcare-of-the-future/',
      '/our-works/lab-web-portal/',
      '/our-works/medical-information-system-software/'
    ],
    date: new Date(2023, 7, 28, 7, 3, 0, 0)
  },
  {
    title: 'AI-Driven Platform That Enables Value-Based Sales at Scale',
    tags: [
        ArticleCategoryEnum['Business App'],
        ArticleCategoryEnum['CRM'],
    ],
    url: '/our-works/sales-assistant-software/',
    backgroundImage: 'sales-assistant-software-cover.webp',
    thumbnailImage: 'sales-assistant-software-thumb.webp',
    caseStudyTitle: 'Sales Assistant Software',
    relatedArticleUrls: [
      '/our-works/construction-supply-chain-automation/',
      '/detailed-guide-on-building-enterprise-app/',
      '/our-works/sales-crm-for-education/'
    ],
    date: new Date(2023, 12, 6, 10, 16, 0, 0)
  },
  {
    title: 'STD Status App Development',
    tags: [
        ArticleCategoryEnum['Healthcare'],
        ArticleCategoryEnum['Mobile'],
    ],
    url: '/our-works/std-status-app/',
    backgroundImage: 'std-cover.webp',
    thumbnailImage: 'std-thumb.webp',
    caseStudyTitle: 'STD Status App Development',
    relatedArticleUrls: [
      '/our-works/custom-telemedicine-solution/',
      '/our-works/medical-information-system-software/',
      '/our-works/health-metrics/'
    ],
    date: new Date(2023, 3, 1, 7, 3, 0, 0)
  },
  {
    title: 'Sloper Climbing App',
    tags: [
        ArticleCategoryEnum['Travel & Hospitality'],
        ArticleCategoryEnum['Mobile'],
    ],
    url: '/our-works/sloper-climbing-app/',
    backgroundImage: 'slopper.webp',
    thumbnailImage: 'slopper-thumb.webp',
    caseStudyTitle: 'Sloper Climbing App',
    relatedArticleUrls: [
      '/11-outstanding-free-travel-apps/',
      '/our-works/road-trip-mobile-app/',
      '/our-works/travel-management-mobile-app/'
    ],
    date: new Date(2021, 5, 12, 7, 3, 0, 0)
  },
  {
    title: 'Construction Supply Chain Automation',
    tags: [
        ArticleCategoryEnum['Construction'],
        ArticleCategoryEnum['Digital Transformation'],
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/construction-supply-chain-automation/',
    backgroundImage: 'construction-supply-chain-automation-cover.webp',
    thumbnailImage: 'construction-supply-chain-automation-thumb.webp',
    caseStudyTitle: 'Logiplan',
    relatedArticleUrls: [
      '/our-works/quarry-automation-platform/',
      '/our-works/emergency-notifier/',
      '/erp-for-construction-industry-and-infrastructure/'
    ],
    date: new Date(2023, 11, 7, 13, 55, 0, 0)
  },
  {
    title: 'Migration to Multi-Tenant SaaS Platform',
    tags: [
        ArticleCategoryEnum['Finance'],
        ArticleCategoryEnum['CRM'],
        ArticleCategoryEnum['SaaS Development'],
        ArticleCategoryEnum['Platform'],
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/migration-to-multi-tenant-saas-platform/',
    backgroundImage: 'migration-to-multi-tenant-saas-platform-cover.webp',
    thumbnailImage: 'migration-to-multi-tenant-saas-platform-thumb.webp',
    caseStudyTitle: 'Migration to Multi-Tenant SaaS Platform',
    relatedArticleUrls: [
      '/our-works/land-tax-software/',
      '/detailed-guide-on-building-enterprise-app/',
      '/our-works/sales-assistant-software/'
    ],
    date: new Date(2023, 11, 23, 10, 11, 1, 0)
  },
  {
    title: 'Custom Telemedicine Solution',
    tags: [
        ArticleCategoryEnum['Healthcare'],
        ArticleCategoryEnum['Telemedicine'],
        ArticleCategoryEnum['Platform'],
        ArticleCategoryEnum['Product'],
        ArticleCategoryEnum['Mobile'],
        ArticleCategoryEnum['Web'],
    ],
    url: '/our-works/custom-telemedicine-solution/',
    backgroundImage: 'custom-telemedicine-solution.webp',
    thumbnailImage: 'custom-telemedicine-solution-thumb.webp',
    caseStudyTitle: 'Custom Telemedicine Solution',
    relatedArticleUrls: [
      '/the-pillars-of-a-winning-telehealth-strategy/',
      '/our-works/formula-compounder/',
      '/our-works/health-metrics/'
    ],
    date: new Date(2022, 4, 19, 20, 34, 0, 0)
  },
  {
    title: 'Formula Compounder',
    tags: [
        ArticleCategoryEnum['Healthcare'],
        ArticleCategoryEnum['LIMS'],
        ArticleCategoryEnum['Web'],
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/formula-compounder/',
    backgroundImage: 'formula-compounder.webp',
    thumbnailImage: 'formula-compounder-thumb.webp',
    caseStudyTitle: 'Formula Compounder',
    relatedArticleUrls: [
      '/our-works/lab-web-portal/',
      '/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
      '/our-works/health-metrics/'
    ],
    date: new Date(2020, 12, 8, 11, 10, 0, 0)
  },
  {
    title: 'Lab Web Portal',
    tags: [
        ArticleCategoryEnum['Healthcare'],
        ArticleCategoryEnum['LIMS'],
        ArticleCategoryEnum['Web'],
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/lab-web-portal/',
    backgroundImage: 'lab-web-portal.webp',
    thumbnailImage: 'lab-web-portal-thumb.webp',
    caseStudyTitle: 'Lab Web Portal',
    relatedArticleUrls: [
      '/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
      '/our-works/health-metrics/',
      '/our-works/formula-compounder/'
    ],
    date: new Date(2020, 11, 25, 7, 47, 0, 0)
  },
  {
    title: 'SCOM: Health Check V2',
    tags: [
      ArticleCategoryEnum['Healthcare'],
      ArticleCategoryEnum['HIS'],
      ArticleCategoryEnum['Web'],
      ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/scom-health-check-v2/',
    backgroundImage: 'scom-health-check-v2.webp',
    thumbnailImage: 'scom-health-check-v2-thumb.webp',
    caseStudyTitle: 'SCOM: Health Check V2',
    relatedArticleUrls: [
      '/our-works/lab-web-portal/',
      '/our-works/formula-compounder/',
      '/our-works/health-metrics/'
    ],
    date: new Date(2020, 11, 25, 13, 57, 0, 0)
  },
  {
    title: 'Medical Information System',
    tags: [
        ArticleCategoryEnum['Healthcare'],
        ArticleCategoryEnum['Mobile'],
        ArticleCategoryEnum['Web'],
    ],
    url: '/our-works/medical-information-system-software/',
    backgroundImage: 'medical-information-system-software.webp',
    thumbnailImage: 'medical-information-system-software-thumb.webp',
    caseStudyTitle: 'Medical Information System',
    relatedArticleUrls: [
      '/our-works/lab-web-portal/',
      '/our-works/health-metrics/',
      '/our-works/formula-compounder/'
    ],
    date: new Date(2020, 11, 25, 13, 6, 0, 0)
  },
  {
    title: 'Health Metrics',
    tags: [
        ArticleCategoryEnum['Healthcare'],
        ArticleCategoryEnum['HIS'],
        ArticleCategoryEnum['Web'],
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/health-metrics/',
    backgroundImage: 'health-metrics.webp',
    thumbnailImage: 'health-metrics-thumb.webp',
    caseStudyTitle: 'Health Metrics',
    relatedArticleUrls: [
      '/our-works/lab-web-portal/',
      '/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
      '/our-works/formula-compounder/'
    ],
    date: new Date(2020, 11, 25, 11, 57, 0, 0)
  },
  {
    title: 'Quarry Automation Platform',
    tags: [
      ArticleCategoryEnum['Energy & Utilities'],
      ArticleCategoryEnum['Mobile'],
      ArticleCategoryEnum['Web'],
    ],
    url: '/our-works/quarry-automation-platform/',
    backgroundImage: 'quarry-automation-platform.webp',
    thumbnailImage: 'quarry-automation-platform-thumb.webp',
    caseStudyTitle: 'Quarry Automation Platform',
    relatedArticleUrls: [
      '/our-works/emergency-notifier/',
      '/our-works/construction-supply-chain-automation/',
      '/supply-chain-management-software-features-requirements/'
    ],
    date: new Date(2021, 5, 12, 7, 18, 0, 0)
  },
  {
    title: 'Logistics Web and Mobile Software',
    tags: [
        ArticleCategoryEnum['Logistics'],
        ArticleCategoryEnum['Web'],
        ArticleCategoryEnum['Mobile'],
    ],
    url: '/our-works/logistics-web-mobile-software/',
    backgroundImage: 'logistics-web-mobile-software.webp',
    thumbnailImage: 'logistics-web-mobile-software-thumb.webp',
    caseStudyTitle: 'Logistics Web and Mobile Software',
    relatedArticleUrls: [
      '/what-is-warehouse-management-system/',
      '/dynamic-route-planning-and-optimization-explained/',
      '/trucking-app-development-guide/'
    ],
    date: new Date(2021, 5, 6, 9, 11, 0, 0)
  },
  {
    title: 'MYS Mobile GPS Tracker',
    tags: [
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/mind-your-service-mobile-gps-tracker/',
    backgroundImage: 'mind-your-service-mobile-gps-tracker.webp',
    thumbnailImage: 'mind-your-service-mobile-gps-tracker-thumb.webp',
    caseStudyTitle: 'MYS Mobile GPS Tracker',
    relatedArticleUrls: [
      '/our-works/ev-charging-software/',
      '/trucking-app-development-guide/',
      '/dynamic-route-planning-and-optimization-explained/'
    ],
    date: new Date(2020, 11, 24, 15, 53, 0, 0)
  },
  {
    title: 'Electric Vehicle Charging Software',
    tags: [
        ArticleCategoryEnum['Logistics'],
        ArticleCategoryEnum['Mobile']
    ],
    url: '/our-works/ev-charging-software/',
    backgroundImage: 'ev-charging-software.webp',
    thumbnailImage: 'ev-charging-software-thumb.webp',
    caseStudyTitle: 'Electric Vehicle Charging Software',
    relatedArticleUrls: [
      '/our-works/mind-your-service-mobile-gps-tracker/',
      '/our-works/freelance-marketplace-development/',
      '/dynamic-route-planning-and-optimization-explained/'
    ],
    date: new Date(2020, 11, 23, 11, 7, 0, 0)
  },
  {
    title: 'Road Trip Mobile App',
    tags: [
        ArticleCategoryEnum['Travel & Hospitality'],
        ArticleCategoryEnum['Mobile'],
    ],
    url: '/our-works/road-trip-mobile-app/',
    backgroundImage: 'road-trip-mobile-app.webp',
    thumbnailImage: 'road-trip-mobile-app-thumb.webp',
    caseStudyTitle: 'Road Trip Mobile App',
    relatedArticleUrls: [
      '/our-works/sloper-climbing-app/',
      '/our-works/travel-management-mobile-app/',
      '/our-works/ev-charging-software/'
    ],
    date: new Date(2021, 9, 16, 10, 4, 0, 0)
  },
  {
    title: 'Outdoor Ads Management Mobile App',
    tags: [
        ArticleCategoryEnum['Media & Entertainment'],
    ],
    url: '/our-works/working-bicycle/',
    backgroundImage: 'working-bicycle.webp',
    thumbnailImage: 'working-bicycle-thumb.webp',
    caseStudyTitle: 'Outdoor Ads Management Mobile App',
    relatedArticleUrls: [
      '/how-to-build-a-product-from-scratch/',
      '/our-works/road-trip-mobile-app/',
      '/our-works/ev-charging-software/'
    ],
    date: new Date(2021, 9, 1, 15, 32, 0, 0)
  },
  {
    title: 'Travel Management Mobile App',
    tags: [
      ArticleCategoryEnum['Travel & Hospitality'],
      ArticleCategoryEnum['Mobile'],
    ],
    url: '/our-works/travel-management-mobile-app/',
    backgroundImage: 'travel-management-mobile-app.webp',
    thumbnailImage: 'travel-management-mobile-app-thumb.webp',
    caseStudyTitle: 'Travel Management Mobile App',
    relatedArticleUrls: [
      '/11-outstanding-free-travel-apps/',
      '/our-works/sloper-climbing-app/',
      '/our-works/road-trip-mobile-app/'
    ],
    date: new Date(2021, 5, 12, 7, 6, 0, 0)
  },
  {
    title: 'Agriculture Software Solutions',
    tags: [
        ArticleCategoryEnum['Agriculture'],
        ArticleCategoryEnum['Mobile'],
        ArticleCategoryEnum['Web'],
    ],
    url: '/our-works/agriculture-software-solutions/',
    backgroundImage: 'agriculture-software-solutions.webp',
    thumbnailImage: 'agriculture-software-solutions-thumb.webp',
    caseStudyTitle: 'Agriculture Software Solutions',
    relatedArticleUrls: [
      '/what-is-warehouse-management-system/',
      '/dynamic-route-planning-and-optimization-explained/',
      '/trucking-app-development-guide/'
    ],
    date: new Date(2021, 5, 11, 6, 14, 0, 0)
  },
  {
    title: 'Food safety management software',
    tags: [
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/audit-management-system/',
    backgroundImage: 'audit-management-system.webp',
    thumbnailImage: 'audit-management-system-thumb.webp',
    caseStudyTitle: 'Food safety management software',
    relatedArticleUrls: [
      '/our-works/lab-web-portal/',
      '/our-works/emergency-notifier/',
      '/our-works/agriculture-software-solutions/'
    ],
    date: new Date(2020, 11, 25, 15, 13, 0, 0)
  },
  {
    title: 'Emergency Notifier',
    tags: [
        ArticleCategoryEnum['Construction'],
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/emergency-notifier/',
    backgroundImage: 'emergency-notifier.webp',
    thumbnailImage: 'emergency-notifier-thumb.webp',
    caseStudyTitle: 'Emergency Notifier',
    relatedArticleUrls: [
      '/our-works/mind-your-service/',
      '/our-works/construction-supply-chain-automation/',
      '/our-works/quarry-automation-platform/'
    ],
    date: new Date(2020, 11, 25, 10, 33, 0, 0)
  },
  {
    title: 'Bridgestone Survey Mobile App',
    tags: [
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/bridgestone-car-park-survey/',
    backgroundImage: 'bridgestone-car-park-survey.webp',
    thumbnailImage: 'bridgestone-car-park-survey-thumb.webp',
    caseStudyTitle: 'Bridgestone Survey Mobile App',
    relatedArticleUrls: [
      '/our-works/network-deposition-services/',
      '/our-works/time-booking-tool/',
      '/our-works/hostoo/'
    ],
    date: new Date(2020, 11, 24, 16, 34, 0, 0)
  },
  {
    title: 'Freelance Marketplace Development',
    tags: [
        ArticleCategoryEnum['Education'],
        ArticleCategoryEnum['Platform'],
        ArticleCategoryEnum['Mobile'],
        ArticleCategoryEnum['Web'],
    ],
    url: '/our-works/freelance-marketplace-development/',
    backgroundImage: 'freelance-marketplace-development.webp',
    thumbnailImage: 'freelance-marketplace-development-thumb.webp',
    caseStudyTitle: 'Freelance Marketplace Development',
    relatedArticleUrls: [
      '/how-to-build-a-product-from-scratch/',
      '/our-works/sales-crm-for-education/',
      '/our-works/fee-foundation-of-economic-education/'
    ],
    date: new Date(2022, 11, 30, 10, 18, 0, 0)
  },
  {
    title: 'Land Tax Management Software Development',
    tags: [
        ArticleCategoryEnum['Finance']
    ],
    url: '/our-works/land-tax-software/',
    backgroundImage: 'land-tax-software.webp',
    thumbnailImage: 'land-tax-software-thumb.webp',
    caseStudyTitle: 'Land Tax Management Software Development',
    relatedArticleUrls: [
      '/our-works/migration-to-multi-tenant-saas-platform/',
      '/detailed-guide-on-building-enterprise-app/',
      '/our-works/construction-supply-chain-automation/'
    ],
    date: new Date(2022, 4, 20, 9, 56, 0, 0)
  },
  {
    title: 'CRM for Education Industry',
    tags: [
        ArticleCategoryEnum['Education'],
        ArticleCategoryEnum['CRM'],
        ArticleCategoryEnum['Business App'],
        ArticleCategoryEnum['Web'],
    ],
    url: '/our-works/sales-crm-for-education/',
    backgroundImage: 'sales-crm-for-education.webp',
    thumbnailImage: 'sales-crm-for-education-thumb.webp',
    caseStudyTitle: 'CRM for Education Industry',
    relatedArticleUrls: [
      '/our-works/migration-to-multi-tenant-saas-platform/',
      '/our-works/freelance-marketplace-development/',
      '/detailed-guide-on-building-enterprise-app/'
    ],
    date: new Date(2022, 4, 19, 20, 19, 0, 0)
  },
  {
    title: 'Scan Tool',
    tags: [
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/scan-tool/',
    backgroundImage: 'scan-tool.webp',
    thumbnailImage: 'scan-tool-thumb.webp',
    caseStudyTitle: 'Scan Tool',
    relatedArticleUrls: [
      '/our-works/lab-web-portal/',
      '/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
      '/our-works/health-metrics/'
    ],
    date: new Date(2020, 12, 8, 10, 44, 0, 0)
  },
  {
    title: 'MindYourService',
    tags: [
        ArticleCategoryEnum['Logistics'],
        ArticleCategoryEnum['Asset Monitoring'],
    ],
    url: '/our-works/mind-your-service/',
    backgroundImage: 'mind-your-service.webp',
    thumbnailImage: 'mind-your-service-thumb.webp',
    caseStudyTitle: 'MindYourService',
    relatedArticleUrls: [
      '/our-works/network-deposition-services/',
      '/detailed-guide-on-building-enterprise-app/',
      '/our-works/scan-tool/'
    ],
    date: new Date(2020, 11, 25, 19, 59, 0, 0)
  },
  {
    title: 'WellsUp Oil and Gas Software',
    tags: [
        ArticleCategoryEnum['Energy & Utilities'],
        ArticleCategoryEnum['Mobile'],
        ArticleCategoryEnum['Web'],
    ],
    url: '/our-works/wells-up/',
    backgroundImage: 'wells-up.webp',
    thumbnailImage: 'wells-up-thumb.webp',
    caseStudyTitle: 'WellsUp Oil and Gas Software',
    relatedArticleUrls: [
      '/our-works/quarry-automation-platform/',
      '/our-works/emergency-notifier/',
      '/detailed-guide-on-building-enterprise-app/'
    ],
    date: new Date(2020, 11, 25, 14, 54, 0, 0)
  },
  {
    title: 'Time Booking Tool',
    tags: [
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/time-booking-tool/',
    backgroundImage: 'time-booking-tool.webp',
    thumbnailImage: 'time-booking-tool-thumb.webp',
    caseStudyTitle: 'Time Booking Tool',
    relatedArticleUrls: [
      '/our-works/network-deposition-services/',
      '/our-works/hostoo/',
      '/our-works/audit-management-system/'
    ],
    date: new Date(2020, 11, 25, 14, 13, 0, 0)
  },
  {
    title: 'Network Deposition Services',
    tags: [
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/network-deposition-services/',
    backgroundImage: 'network-deposition-services.webp',
    thumbnailImage: 'network-deposition-services-thumb.webp',
    caseStudyTitle: 'Network Deposition Services',
    relatedArticleUrls: [
      '/our-works/time-booking-tool/',
      '/our-works/hostoo/',
      '/our-works/scan-tool/'
    ],
    date: new Date(2020, 11, 25, 13, 27, 0, 0)
  },
  {
    title: 'Hostoo',
    tags: [
        ArticleCategoryEnum['Business App'],
    ],
    url: '/our-works/hostoo/',
    backgroundImage: 'hostoo.webp',
    thumbnailImage: 'hostoo-thumb.webp',
    caseStudyTitle: 'Hostoo',
    relatedArticleUrls: [
      '/our-works/bridgestone-car-park-survey/',
      '/our-works/audit-management-system/',
      '/our-works/scan-tool/'
    ],
    date: new Date(2020, 11, 25, 13, 18, 0, 0)
  },
  {
    title: 'Foundation for EE',
    tags: [
        ArticleCategoryEnum['Education'],
        ArticleCategoryEnum['Web'],
    ],
    url: '/our-works/fee-foundation-of-economic-education/',
    backgroundImage: 'fee-foundation-of-economic-education.webp',
    thumbnailImage: 'fee-foundation-of-economic-education-thumb.webp',
    caseStudyTitle: 'Foundation for EE',
    relatedArticleUrls: [
      '/our-works/freelance-marketplace-development/',
      '/our-works/sales-crm-for-education/',
      '/our-works/scan-tool/'
    ],
    date: new Date(2020, 11, 25, 11, 26, 0, 0)
  },
  {
    title: 'Improving Quality Assurance in Tech Projects of a Top US-Based Bank',
    tags: [
        ArticleCategoryEnum['Web'],
        ArticleCategoryEnum['Mobile']
    ],
    url: '/our-works/qa-services-for-bank/',
    backgroundImage: 'qa-services-for-bank-bg.webp',
    thumbnailImage: 'qa-services-for-bank-thumb.webp',
    caseStudyTitle: 'Improving Quality Assurance in Tech Projects of a Top US-Based Bank',
    relatedArticleUrls: [
      '/our-works/migration-to-multi-tenant-saas-platform/',
      '/our-works/land-tax-software/',
      '/how-to-build-a-mobile-banking-app/'
    ],
    date: new Date(2024, 1, 3, 8, 45, 0, 0)
  },
	{
		title: 'A Data Management, Reporting & Analytics Solution for Tax Offices in the US',
		tags: [
				ArticleCategoryEnum['Business App'],
				ArticleCategoryEnum['Finance'],
				ArticleCategoryEnum['Digital payments']
		],
		url: '/our-works/data-management-tool-for-tax-offices/',
		backgroundImage: 'data-management-tool-for-tax-offices-bg.webp',
		thumbnailImage: 'data-management-tool-for-tax-offices-thumb.webp',
		caseStudyTitle: 'A Data Management, Reporting & Analytics Solution for Tax Offices in the US',
		relatedArticleUrls: [
		  '/our-works/hostoo/',
		  '/our-works/migration-to-multi-tenant-saas-platform/',
		  '/our-works/network-deposition-services/'
		],
		date: new Date(2024, 4, 1, 8, 21, 15, 0)
		},
	{
		title: 'A Digital Solution for Veteran Rehabilitation Center',
		tags: [
				ArticleCategoryEnum['Healthcare'],
				ArticleCategoryEnum['Telemedicine'],
		],
		url: '/our-works/digital-solution-for-veteran-rehabilitation-center/',
		backgroundImage: 'digital-solution-for-veteran-rehabilitation-center-bg.webp',
		thumbnailImage: 'digital-solution-for-veteran-rehabilitation-center-thumb.webp',
		caseStudyTitle: 'A Digital Solution for Veteran Rehabilitation Center',
		relatedArticleUrls: [
		  '/our-works/saas-based-resident-experience-platform-for-senior-living/',
		  '/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
		  '/our-works/health-metrics/'
		],
		date: new Date(2024, 8, 8, 15, 15, 11, 0)
		},
];

export const reviews: Review[] = [
  {
    text: 'We developed these software solutions for a chain of medical clinics in Canada and Mexico. Seeking to embrace digitalization and introduce telemedicine, the client asked us to build an app with features like personal profiles, access to medical records, and scheduling. Additionally, we had to develop a web portal to automate basic tasks for doctors and medical staff. Our team successfully implemented this project from scratch, enabling the client to expand their reach with remote services',
    jobTitle: "",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['business-digitalization-for-a-chain-of-canadian-medical-clinics']
  },
  {
    text: 'Exoft is well-adjusted to the client’s timeline, development cycle, and requirements. The team fosters transparency and open communication, which have been pivotal in managing the client’s expectations and recalibrating the quality of outsourced developers.It was crucial for developing our modern sales assistant software.',
    jobTitle: 'The Company’s Executive',
    source: 'clutch',
    starsCount: 5,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['sales-assistant-software']
  },
  {
    text: 'The main reason I enjoy working with Exoft is that they produce consistently high-quality work. We looked at several outsourced solutions for our development activities and found that Exoft provides skilled resources at very good hourly rates. The developers continuously strive to understand our requirements and build robust solutions.',
    jobTitle: 'The client’s Product Owner',
    source: 'clutch',
    starsCount: 5,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['std-status-app']
  },
  {
    text: 'Exoft offers a talented and dedicated team that has successfully helped expedite the development process. They have proven to be reliable and accommodating, easily adjusting their workflow to match project requirements...',
    jobTitle: 'Slava Ceornea, CTO',
    source: 'clutch',
    starsCount: 5,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['sloper-climbing-app']
  },
  {
    text: 'We have built an all-in-one platform that gives control over every aspect of the construction supply chain. This solution, which caters to over 200 construction professionals, includes two components: one that addresses supply chain needs on a construction site and the other one intended for suppliers, distributors, and logistics terminal personnel. We worked on this project from ideation to post-release, rolling out the necessary updates.',
    jobTitle: '',
    source: 'clutch',
    starsCount: 5,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['construction-supply-chain-automation']
  },
  {
    text: 'We created a cutting-edge telemedicine platform supporting video calls, chats, and payments. Available to patients from around the globe, it gives doctors an extra source of income alongside their primary job. The solution helped the client become a leader in the rapidly growing telehealth market.',
    jobTitle: "",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['custom-telemedicine-solution']
  },
  {
    text: 'Our team built this robust laboratory information management system (LIMS) for a European pharmaceutical company struggling to streamline its drug manufacturing process. The LIMS offers editable templates for simplified formula compounding, formula usage tracking, a component database, and more. Prior to building this solution, we did thorough market research and mapped the user journey. The client highly appreciated our work and provided positive feedback',
    jobTitle: "",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['formula-compounder']
  },
  {
    text: 'Great understanding. We were on the same page from the very beginning',
    jobTitle: "CMO at medical center",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['lab-web-portal']
  },
  {
    text: 'They’re a wonderful company, and we thoroughly enjoyed working with them',
    jobTitle: "The client's CMO",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['scom-health-check-v2']
  },
  {
    text: 'We developed this solution for a client whose aim was to modernize healthcare organizations by transforming clinician-patient interactions and streamlining hospital workflows. Building this medical information system from the ground up, our team implemented video conferencing, in-app messaging, notifications, and other features. Ensuring robust data protection was our top priority.',
    jobTitle: "VP of Innovation & Development at the Client's Organization",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['medical-information-system-software']
  },
  {
    text: 'On the design side of things and in general, their coding ability is very high',
    jobTitle: "The client's CEO",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['health-metrics']
  },
  {
    text: 'Exoft has handled all the tasks I’ve given them very well. They were willing to work longer hours when we needed something urgently. They’re always thinking about how our projects can be improved, and they always perform research before executing a task.',
    jobTitle: "The client's Product Owner",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['quarry-automation-platform']
  },
  {
    text: 'We created this solution for a top logistics company with a vast carrier network, a fleet of over 60,000 vehicles, and handling over 100,000 deliveries monthly. Eager to enhance customer service, they commissioned us to develop a web system and a mobile app to streamline operations, automate tasks, enable real-time tracking, and more. We took up the project at the idea stage and supported it all the way to its launch, ensuring advanced security and usability.',
    jobTitle: '',
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['logistics-web-mobile-software']
  },
  {
    text: 'Our team built this mobile app for a UK-based software vendor. They aimed to develop a solution allowing companies to track their field employees\' locations using GPS technology. The app offers real-time location monitoring, task assignment, movement history tracking, and other features. We joined the project early on after the previous team struggled to meet expectations and supported it until the app’s successful release.',
    jobTitle: '',
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['mind-your-service-mobile-gps-tracker']
  },
  {
    text: 'Company provided services as requested! We did not have to use resources to recruit or administer for these roles',
    jobTitle: "Head of Engineering at the client's company",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['ev-charging-software']
  },
  {
    text: 'We were pleased with the results of our product. Exoft provided quick and efficient workflow, and their precise estimates made us consider them a trusted partner with great experience. They were always responsive, accessible, and friendly.',
    jobTitle: "The client's Product Owner",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['road-trip-mobile-app']
  },
  {
    text: 'Functionalities produced by Exoft have significantly improved website performance. Exoft displayed quality communication and responded to queries fast.',
    jobTitle: "Startup Founder & CEO",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['working-bicycle']
  },
  {
    text: 'We definitely had a positive experience working with Exoft. When we reviewed their work, the app had very few bugs, and we didn’t request very many changes to their work. They followed our design very closely.',
    jobTitle: "Startup Founder & CEO",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['travel-management-mobile-app']
  },
  {
    text: 'They successfully developed solution has garnered considerable industry praise. Exoft have received very positive feedback from medical practitioners',
    jobTitle: "",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['agriculture-software-solutions']
  },
  {
    text: 'They are easy to work with',
    jobTitle: "CEO at the client's company",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['audit-management-system']
  },
  {
    text: 'An emergency notifier mobile app we created for a construction company serves as a go-to tool for sending urgent messages across communication channels. The message recipients are office and remote employees who use various devices, such as computers, smartphones, and tablets. Our team built the app from the ground up and seamlessly integrated it with the client\'s corporate systems, including legacy ones, ensuring smooth functionality across the board. ',
    jobTitle: "",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['emergency-notifier']
  },
  {
    text: 'We consider Exoft as a partner, not just a vendor.',
    jobTitle: "Head of BTL at Bridgestone",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['bridgestone-car-park-survey']
  },
  {
    text: 'The amount of knowledge and expertise Exoft has in freelance marketplace development is impressive. Their team always engaged with us to help refine online platform features to make them the best they can be, going a level deeper than just developing what we request. They were great at meeting all UI/UX and functional requirements for our freelance marketplace, which was very helpful. Exoft is a great, trustworthy, and talented team that goes above and beyond to help deliver freelance market products of great value. ',
    jobTitle: "CTO at Eloquenti",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['freelance-marketplace-development']
  },
  {
    text: 'We developed this comprehensive tax management solution for a US-based consulting company. The platform assists tax professionals in calculating land tax, filing tax returns, and ensuring overall tax compliance. The key challenge was to ensure the fast retrieval of information from a large database, which we addressed using Apache Solr — a Java platform enabling full-text search and indexing. Our guidance throughout the three-year project spanned from ideation to post-release product updates.',
    jobTitle: "",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['land-tax-software']
  },
  {
    text: 'So far, it’s been a successful partnership. The Exoft team has been passing deliverables on time and with utmost quality. They’ve mostly impressed us with their quick problem-solving skills, which continue to ensure a delay-free engagement. They are also honest, flexible, and communicative.',
    jobTitle: "The company's Director",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['sales-crm-for-education']
  },
  {
    text: 'I’ve expressed great satisfaction with the completed by Exoft',
    jobTitle: "",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['scan-tool']
  },
  {
    text: 'We were impressed by how they met our expectations and how quickly they delivered',
    jobTitle: "CTO at MYS",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['mind-your-service']
  },
  {
    text: 'Throughout the relationship, Exoft has been an extremely supportive partner. In addition to communicating in a timely manner, the team is proactive and reactive, quickly addressing any issues that arise. Their ability to work with out-of-the-box ideas sets them apart from other vendors',
    jobTitle: "Sami Suheil, COO at WellsUp",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['wells-up']
  },
  {
    text: 'I would definitely recommend them. Attention to detail and constant feedback is impressive.',
    jobTitle: "COO at client's company",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['time-booking-tool']
  },
  {
    text: 'Exoft is open-minded. We talk openly about things, and we don’t hide anything. It’s perfect. They are perfect. Take them as they are. They are cool and produce great quality work',
    jobTitle: "Client's CEO",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['network-deposition-services']
  },
  {
    text: 'Excellent. Both our team and Exoft’s acted as a unit',
    jobTitle: "Managing Director",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['hostoo']
  },
  {
    text: 'The team did even more than they were asked to do',
    jobTitle: "COO at FEE",
    source: undefined,
    starsCount: 0,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['fee-foundation-of-economic-education']
  },
  {
    text: 'We smoothly transitioned the client’s CRM platform from a desktop version to a multi-tenant SaaS solution without disrupting business operations. Post-migration, we built a series of new features to meet evolving user needs. As a result, the client received a cutting-edge CRM system that empowers customer-centric service. The system actively measures customer engagement, unifies communication across channels, provides data-driven insights into customer experience, and enhances account management.',
    jobTitle: '',
    source: undefined,
    starsCount: 5,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['migration-to-multi-tenant-saas-platform']
  },
  {
    text: 'We turned the client’s idea into a full-fledged SaaS solution that improves senior living with meaningful social interactions. The platform connects nursing home residents, their families, caregivers, and service providers. This achievement earned the client the title of the International AgeTech StartUp of the Year 2022.',
    jobTitle: '',
    source: undefined,
    starsCount: 5,
    url: 'https://clutch.co/profile/exoft',
    relatedArticleUrls: ['saas-based-resident-experience-platform-for-senior-living']
  },
];

export const technologies: Service[] = [
  {
    iconName: '',
    title: '.NET',
    content: "Using .NET in software development services for startups makes it possible to create high-quality software products. They include mobile apps, web apps, and different desktop platforms. Besides, utilizing the advanced tech stack provides many critical advantages to the PoC development process. With our .NET software developing company working with startups, your organization will reach its business goals and ensure constant business growth.",
    url: '/net-software-development-company/'
  },
  {
    iconName: '',
    title: 'Java',
    content: 'Our IT software development company has significant expertise in creating robust Java-based software products. That allows for improving the basic functionality of your solution and the general customer experience. As an experienced provider of Java software development services, including enterprise, desktop, cloud, and web application development, Exoft can create any mobile app development product depending on your business requirements.',
    url: '/java-development-services/'
  },
  {
    iconName: '',
    title: 'ReactJs',
    content: 'Using ReactJS, our business software development company helps create fast-loading digital solutions that meet customer needs and create business value. Regarding startups, software development with ReactJS can cover different requests, ranging from simple applications to large-scale platforms. After all, ReactJS development for startups enables programmers to build highly responsive user interfaces, add new features, and increase performance efficiently.',
    url: '/reactjs-development-services/'
  },
  {
    iconName: '',
    title: 'Angular',
    content: 'Thanks to our Angular software development services, customers obtain highly dynamic web apps creating value for their organizations. Exoft, as a software programming company, will support you through all stages of the Angular app development, including requirement gathering, automated testing, deploying, and further maintenance. In addition, our developers have strong coding skills, which helps us deliver the best possible solutions following the current industry standards and tech trends like data analytics, cloud services, artificial intelligence (AI), internet of things (IoT), etc.',
    url: '/angular-development-services/'
  },
  {
    iconName: '',
    title: 'Xamarin',
    content: 'Xamarin mobile app development for startups is incredibly beneficial as it allows for building a top-notch application that will run smoothly on different platforms like Android, iOS, and Windows. At the same time, that provides a native-like user experience. With Exoft’s Xamarin developers, you will reduce development time and costs but not compromise your product’s quality. That makes Xamarin a priority for companies in various industries, ensuring their efficient digital transformation.',
    url: '/xamarin-app-development-services/'
  },
  {
    iconName: '',
    title: 'Flutter',
    content: "Our software engineering company utilizes Flutter for mobile app development to create up-to-date applications with top-notch native interfaces. As a reliable custom software developer, Exoft can build an effective Flutter app that will run correctly on Android and iOS. Therefore, we provide end-to-end mobile development services using Flutter to satisfy the customer’s unique needs and ensure proper native performance via required devices.",
    url: ''
  }
];

export const industries: Industry[] = [
  {
    title: 'Healthcare',
    content: 'With our team extension services, you can get all the tech experts you need to build a top-notch healthtech solution for your medical organization.<br><br>Our software developers specialize in creating diverse digital healthcare products, ranging from cutting-edge telemedicine platforms to robust EHR/EMR systems. We also uphold strict industry security standards and regulations, including HIPAA, ensuring your software is both dependable and regulatory-aligned.',
    url: '/healthcare-software-development/',
    icon: 'healthcare.webp'
  },
  {
    title: 'Logistics',
    content: 'Choosing our software team extension services empowers you to create innovative software solutions with the potential to propel your logistics business forward.<br><br>By enlisting our experts for your extended development team, you get access to unique tech skills and domain-specific experience. Our software developers can craft advanced systems to optimize fleet routes, streamline supply chains, automate warehouse operations, track shipments, and more — all while seamlessly integrating into your development processes.',
    url: '/logistics-software-development/',
    icon: 'logistics.webp'
  },
  {
    title: 'Finance',
    content: 'By building a remote team with our experts, you can fuel innovation and growth in your fintech business, all while saving time and costs.<br><br>With a proven track record of fintech software development projects, we\'re well-versed in crafting high-quality solutions that align with industry regulations. Whether it\'s mobile banking apps, personal finance management tools, investment and trading software, or personal loan platforms, you can rest assured we can help you make it a reality.',
    url: '/finance-software-development/',
    icon: 'finance.webp'
  },
  {
    title: 'Construction',
    content: 'We help construction businesses build quality solutions faster with our IT team extension services.<br><br>By selecting our software development company as your reliable partner, you gain access to highly skilled professionals who truly understand your industry. Our experts can help you develop comprehensive management systems for resource allocation, task assignment, subcontractor communication, and more tailored to your specific needs.',
    url: '/construction-software-development/',
    icon: 'construction.webp'
  },
];

export const references: ClientReference[] = [
  {
    text: 'It feels like we’re working with an extension of our own company. Their culture is a perfect match for the client, making it feel like they’re a real part of the team',
    name: 'Sandy Steele',
    jobTitle: 'Client Success Manager at BIS Consulting',
    logoUrl: '/teresa.webp'
  }
];

export const feed: Feedback[] = [
  {
    icon: 'boris',
    text: 'Exoft has successfully supported us to push our business forward. They bounce ideas off each other and give feedback and context to help in tech areas.<br><br>Moreover, they’re approachable, responsive partners, and the team stands out for their expertise. It feels more like an internal team than an outsourcing relationship. thank you. ',
    jobTitle: 'Boris Jinjolava, CEO at ViCLinic Inc. ',
    source: 'clutch',
    url: 'https://clutch.co/profile/exoft?_gl=1*owj4v9*_ga*MjAyNDI5Njg5Mi4xNjM0NzM1NTA5*_ga_D0WFGX8X3V*MTY3NjM4MDMxMS4xNjIuMC4xNjc2MzgwMzEyLjU5LjAuMA..*_fplc*bkw0bFppQmxHYUdDYXB1WWl1SkI1RWNCUUlWJTJGM2pKSnpyZm9EWW8zMFlySTk1SFNMbElxaDNTRlRwa0FvVm1sMWpHNlNySlpDdllEOSUyRkRkQTFsVHNxTUl5RW93UmkxTEo0cjBwcnJITWZoV2IxVXVYV0hMOWl4VVBDWWVIUSUzRCUzRA..#summary',
    relatedArticleUrls: []
  },
  {
    icon: 'slava',
    text: 'Exoft is passionate about what they do. <br><br> They have successfully completed the first phase of the' +
        ' project and is working on the second phase of the migration. Collaborating with the internal team, the' +
        ' vendor works on the deliverables with transparency and dedication. <br><br> They listen to the client\'s ideas and ensure a high-quality end product.',
    jobTitle: 'CTO at ANALEC Infotech Private',
    source: 'designrush',
    url: 'https://www.designrush.com/agency/profile/exoft',
    relatedArticleUrls: []
  },
  {
    icon: 'fred',
    text: 'The team was very skilled and well-versed in state-of-the-art technologies.<br><br>Throughout the project, I had an impression that they not only perform the assigned tasks but are constantly and creatively thinking about how to improve our project beyond what we have asked for.<br><br>Outstanding quality of work provided at the agreed upon deadlines. On a personal level, very friendly people',
    jobTitle: 'CTO at Online Marketplace',
    source: 'designrush',
    url: 'https://www.designrush.com/agency/profile/exoft',
    relatedArticleUrls: []
  }
];

export const whyUs: BasicContent[] = [
  {
    iconName: '?',
    title: 'Strong expertise',
    content: "Our dedicated development team consists of experienced tech savvies truly focused on the work they do. We use best practices and systematic approach, know industry trends, and constantly improve.",
  },
  {
    iconName: '?',
    title: 'Comprehensive approach',
    content: 'To offer the best possible solution, our software development firm analyzes the client’s business goals and the main idea behind a product before writing the first line of code.',
  },
  {
    iconName: '?',
    title: 'Flexible cooperation model',
    content: 'We can adapt our software development process to the project needs and allow our clients to choose a cooperation model that suits their financial capabilities best.',
  },
  {
    iconName: '?',
    title: 'Fair pricing',
    content: 'As a trusted software development partner, Exoft offers supreme-quality services at a reasonable price and tries to increase the project’s cost-effectiveness in every single case.',
  },
  {
    iconName: '?',
    title: 'Client-centric attitude',
    content: 'For our software development company, client satisfaction is the priority. That is why we strive to exceed the initial expectations in many ways.',
  },
  {
    iconName: '?',
    title: 'Transparency',
    content: "We believe that trust is the foundation for success. Our clients have full access to their software engineering projects and can make sure that everything goes smoothly at any time.",
  },
];

export const vacancies: Vacancy[] = [
	{
				position: 'Automation QA Engineer (Python)',
				category: 'Engineering',
				occupancy: 'Full-time',
				url: '/automation-qa-engineer/',
				location: 'Lviv, Ukraine',
				jobOverview: 'We are seeking an experienced Automation QA Engineer with a strong background in Python to join our team. The ideal candidate will be responsible for ensuring the quality and reliability of water resource management application, which plays a crucial role in monitoring and maintaining essential environmental systems.',
				responsibilities: [
						'Develop and execute automated tests using Selenium WebDriver with Python to ensure the application\'s functionality and performance.',
						'Work with Rest Assured to test RESTful APIs and ensure seamless integration across platforms.',
						'Manage version control using Bitbucket and maintain codebase integrity.',
						'Design, develop, and execute manual and automated test cases to cover all aspects of the application.',
						'Collaborate with the development team to troubleshoot and resolve issues.',
						'Ensure accurate documentation and reporting of all testing phases and outcomes.',
						'Communicate effectively with the team to ensure high-quality software development and deployment.'
				],
				requirements: [
						'4+ years of experience as a QA engineer.',
						'Proven experience in automation testing using Selenium WebDriver with Python for web applications.',
						'Experience with Rest Assured.',
						'Experience with Bitbucket.',
						'Experience executing/writing manual and automated test cases.',
						'Upper-Intermediate level of English proficiency.'
				],
				willBePlus: ['Understanding of a cloud infrastructure tool (e.g., AWS, Azure, etc.).'],
				benefits: [
						'Probation period – 3 (three) month',
						'Paid vacation — 18 days + 1 day for each year with us',
						'Paid sick leave (10 days of your base year)',
						'COVID-19 sick leave and treatment',
						'Wellness program (sport, healthcare)',
						'50% compensation for educational matters',
						'Free English courses;',
						'Teambuildings and corporate events'
				]
		},
	{
				position: 'Automation QA Engineer (Python, Selenium)',
				category: 'Engineering',
				occupancy: 'Full-time',
				url: '/automation-qa-engineer-(python-selenium)/',
				location: 'Lviv, Ukraine',
				jobOverview: 'Exoft is looking for a talented and experienced Automation QA Engineer (Python, Selenium). The project is dedicated to enhancing the congruence between scientific principles and practices with the aim of improving the acquisition and application of knowledge.',
				responsibilities: [
						'Review requirements, specifications and technical design documents to provide timely and meaningful feedback.',
						'Review requirements, specifications and technical design documents and provide feedback and accurate estimations.',
						'Develop and ensure adoption of standards and procedures to determine product quality and release readiness throughout SDLC.',
						'Estimate, prioritize, plan, and coordinate testing activities with release priorities.',
						'Participate in testing and closely follow the test plan executions within Sprints.',
						'Identify and document any improvements and bugs with clear steps to reproduce and any other related details.',
						'Maintain communication and follow up to support fixes in development for release.',
						'Recommend improvements to the product to enhance user experience and performance and collaborate with supervisor to establish QA program go-forward strategy and execution path.',
						'Design, develop, execute and maintain automated test scripts and automated test suite.',
						'Incorporate automated and manual testing methods for full coverage regression testing.',
						'Execute automated and regression testing in accordance with release schedules and best practices.'
				],
				requirements: [
						'3+ years of professional experience',
						'Experience in manual and automated testing of API, Web applications including Mobile friendliness',
						'Experience maintaining and building automation test scripts - Selenium & Webdriver with Python',
						'Experience with testing tools and accessibility testing (Jira & zephyr); performance testing and tools like Jmeter/Blazemeter is a plus',
						'Experience with Django Rest framework, Selenium Webdriver with Python',
						'Upper-intermediate level of English;'
				],
				willBePlus: [],
				benefits: [
						'Probation period – 3 (three) month',
						'Paid vacation — 18 days + 1 day for each year with us',
						'Paid sick leave (10 days of your base year)',
						'COVID-19 sick leave and treatment',
						'Wellness program (sport, healthcare)',
						'50% compensation for educational matters',
						'Free English courses;',
						'Teambuildings and corporate events'
				]
	},
	{
				position: 'Project Manager',
				category: 'Engineering',
				occupancy: 'Full-time',
				url: '/project-manager/',
				location: 'Lviv, Ukraine',
				jobOverview: 'Exoft is looking for a talented and experienced Project Manager to lead and oversee multiple IT projects, from conception to successful delivery. The ideal candidate will have a solid understanding of project management methodologies and tools, ensuring that the projects meet business goals and client expectations.',
				responsibilities: [
						'Manage the full project lifecycle, from project planning and initiation to execution and delivery.',
						'Work closely with cross-functional teams to define project scope, goals, deliverables, and timelines.',
						'Ensure timely and effective communication with stakeholders, providing regular updates on project status and progress.',
						'Utilize project management tools like Jira, Trello, or MS Project to track progress, assign tasks, and manage resources.',
						'Identify potential risks and develop mitigation strategies to ensure project success.',
						'Implement Agile, Scrum, or Waterfall methodologies to suit the specific needs of each project.'
				],
				requirements: [
						'2+ years of experience in IT project management.',
						'Proven track record of managing IT projects from conception to delivery.',
						'Strong knowledge of project management methodologies (Agile, Scrum, Waterfall, etc.).',
						'Proficiency in project management software tools such as Jira, Trello, or MS Project.',
						'At least Upper-Intermediate level of English.'
				],
				willBePlus: ['Technical background.'],
				benefits: [
						'Probation period – 3 (three) month',
						'Paid vacation — 18 days + 1 day for each year with us',
						'Paid sick leave (10 days of your base year)',
						'COVID-19 sick leave and treatment',
						'Wellness program (sport, healthcare)',
						'50% compensation for educational matters',
						'Free English courses;',
						'Teambuildings and corporate events'
				]
	},
	{
				position: 'Python Developer',
				category: 'Engineering',
				occupancy: 'Full-time',
				url: '/python-developer/',
				location: 'Lviv, Ukraine',
				jobOverview: 'We are seeking a talented Python Developer to join our team. The successful candidate will contribute to a project focused on advancing scientific practices and methodologies. You will work on building scalable and robust solutions, while ensuring efficient data processing and analysis.',
				responsibilities: [
						'Develop and maintain Python-based applications with a focus on scalability and performance.',
						'Work with Django to build robust web applications.',
						'Use Google Cloud Platforms, Kubernetes, and Jenkins to deploy and manage applications.',
						'Collaborate with teams to ensure smooth integration with PostgreSQL, Elastic Search, and RabbitMQ.',
						'Write clean, maintainable, and efficient code while contributing to all phases of the development lifecycle.',
						'Participate in code reviews, testing, and debugging to ensure high-quality software delivery.'
				],
				requirements: [
						'2+ years of experience with Python.',
						'A deep understanding of Django.',
						'Experience with Google Cloud Platforms, Kubernetes, and Jenkins.',
						'Familiarity with PostgreSQL, Elastic Search, and RabbitMQ.',
						'Intermediate+ level of English proficiency.'
				],
				willBePlus: ['Experience with JavaScript.'],
				benefits: [
						'Probation period – 3 (three) month',
						'Paid vacation — 18 days + 1 day for each year with us',
						'Paid sick leave (10 days of your base year)',
						'COVID-19 sick leave and treatment',
						'Wellness program (sport, healthcare)',
						'50% compensation for educational matters',
						'Free English courses;',
						'Teambuildings and corporate events'
				]
	},
]

export const technologysStack: object = [
  {
    title: 'Back-end',
    iconName: 'lamp',
    stack: [
      {
        title: '.NET',
        iconName: 'dotNet',
        url:'/net-software-development-company/'
      },
      {
        title: 'Java',
        iconName: 'java',
        url:'/java-development-services/'
      }
    ]
  },
  {
    title: 'Front-end',
    iconName: 'webDevelopment',
    stack: [
      {
        title: 'Angular',
        iconName: 'angular',
        url:'/angular-development-services/'
      },
      {
        title: 'ReactJS',
        iconName: 'react',
        url:'/reactjs-development-services/'
      },
      {
        title: 'Vue.js',
        iconName: 'vueJs',
        url:'/vue-js-development-services/'
      },
    ]
  },
  {
    title: 'Mobile',
    iconName: 'mobileDevelopment',
    stack: [
      {
        title: 'Android',
        iconName: 'android',
        url:'/mobile-development/'
      },
      {
        title: 'iOS',
        iconName: 'ios',
        url:'/mobile-development/'
      },
      {
        title: 'Xamarin',
        iconName: 'xamarin',
        url:'/xamarin-app-development-services/'
      }
    ]
  },

]

export const organizationSchema: object = {
		'@context': 'https://schema.org',
		'@type': 'Organization',
		name: 'Exoft',
		address: {
				'@type': 'PostalAddress',
				streetAddress: '111a Shevchenko Street',
				addressLocality: 'Lviv',
				postalCode: '79060',
				addressCountry: 'Ukraine'
		},
		url: 'https://exoft.net/',
		logo: openGraphImageUrl,
		contactPoint: [
				{
						'@type': 'ContactPoint',
						telephone: '+380639631064',
						contactType: 'consultant'
				}
		],
		sameAs: [
				'https://www.linkedin.com/company/exoft/',
				'https://www.instagram.com/exoftsoftware/',
				'https://www.facebook.com/exoft',
				'https://twitter.com/ExoftSoftware'
		]
};